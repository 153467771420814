import React, { useState, useEffect } from 'react';
import Typography from '../../../../components/atoms/Typography';
import Picture from '../../../../components/atoms/Picture';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import aspectRatios from '../../../../utils/AspectRatios';
import clsx from 'clsx';
import Link from '../../../../components/atoms/Link';
import Favorite from '../../../../components/atoms/Favorite';
import { get } from 'lodash';

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const SlideInner = props => {
  const { index, initInnerSlides = false, slide, isEventsHeroSlider } = props;
  const {
    cta,
    image,
    title,
    secondaryTitle,
  } = slide.fields;
  const [showSlide, setShowSlide] = useState(false);

  const category = get(slide, 'fields.segmentTag.fields.searchKey.value', '');
  const time = get(slide, 'fields.dateAndTime.value', '');
  const id = get(slide, 'id', '');

  useEffect(() => {
    if (index < 3) {
      setShowSlide(true)
    }
  }, [])

  useEffect(() => {
    if (index >= 3 && initInnerSlides) {
      setShowSlide(true)
    }
  }, [initInnerSlides])

  const slideInnerSlide = isEventsHeroSlider
    ? (
      <div className={clsx(props.className)}>
        <div className='slideItemsWrapper'>
          <div className='slidePictureWrapper'>
            <div className='slideElement'>
              <Picture
                media={{
                  image,
                  mobileImage: image,
                  aspectRatio: aspectRatios['4x5'],
                  disableLazyLoad: true,
                  preferredSize: "medium"
                }}
                isParalax={false}
                alternateAltText={title && title.value}
              />
            </div>
          </div>
          <div className='slideInformation'>
            <div className={'slideInformationTop'}>
              {category && (
                <div className={'categoryLabel'}>
                  <Typography variant="body2" component="p" className="categoryLabelText">
                    {category}
                  </Typography>
                </div>
              )}
              {/* <div className={'favouriteWrapper'}>
                <Favorite itemUniqueKey={id} />
              </div> */}
            </div>
            <div className={'slideInformationBottom'}>
              {time && (
                <Typography variant="body2" component="p" className="timeText" >
                  {time}
                </Typography>
              )}
              {title && title.value && (
                <Typography variant="h52" component="h3"
                  className="sliderTitle"
                  animateSettings={{
                    willAnimate: false,
                  }}>
                  {title.value}
                </Typography>
              )}
              {/* <i className={
                clsx(
                  'slideInfoBorder',
                  !(title && title.value) && 'no-title',
                  !(secondaryTitle && secondaryTitle.value) && 'no-sub-title'
                )}></i> */}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={clsx(props.className)}>
        <div className='slidePictureWrapper'>
          <div className='slideElement'>
            <Picture
              media={{
                image,
                mobileImage: image,
                aspectRatio: aspectRatios['4x5'],
                disableLazyLoad: true,
                preferredSize: "medium"
              }}
              isParalax={false}
              alternateAltText={title && title.value}
            />
          </div>
        </div>
        <div className='slideInformation'>
          {title && title.value && (
            <Typography variant="h52" component="h3"
              className="sliderTitle"
              animateSettings={{
                willAnimate: false,
              }}>
              {title.value}
            </Typography>
          )}
          {secondaryTitle && secondaryTitle.value && (
            <Typography variant="body2" component="h3"
              className="sliderSubTitle"
              animateSettings={{
                willAnimate: false,
              }}>
              {secondaryTitle.value}
            </Typography>
          )}
          <i className={
            clsx(
              'slideInfoBorder',
              !(title && title.value) && 'no-title',
              !(secondaryTitle && secondaryTitle.value) && 'no-sub-title'
            )}></i>
        </div>
      </div>
    )
  return (
    <>
      {showSlide && <>
        <ConditionalWrapper
          condition={cta && cta.value && cta.value.href}
          wrapper={children => (
            <Link link={cta} draggable={isEventsHeroSlider ? false : true}>
              {children}
            </Link>
          )}
        >
          {slideInnerSlide}
        </ConditionalWrapper>
      </>}
    </>
  );
};

export default withSitecoreContext()(withNamespaces()(SlideInner));
