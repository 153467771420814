import React, { useState, useEffect, useCallback } from 'react';
import useStyles from './style';
import { canUseDOM } from '../../../utils/canUseDOM';
import { useSelector } from 'react-redux';

import { YOUKU_PLAYER_API_CLIENT_KEY } from '../../../../constants';
import clsx from 'clsx'

const YoukuVideo = ({ youkuVideo, index, isCurrentSlide, posterImage }) => {
  const { playFirstYoukuVideo } = useSelector(state => state.DualHeroSliderReducer);

  const classes = useStyles();
  const [isReady, setIsReady] = useState(false)
  const [deviceType, setDeviceType] = useState("none");
  const divID = `sliderYoukuplayer_${index}`
  const youkuVideoURL = youkuVideo;
  let player;

  useEffect(() => {
    if (canUseDOM) {
      let sUserAgent = navigator.userAgent.toLowerCase();
      let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      let bIsIphone = sUserAgent.match(/iphone/i) == "iphone";
      let bIsMidp = sUserAgent.match(/midp/i) == "midp";
      let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      let bIsAndroid = sUserAgent.match(/android/i) == "android";
      let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      setDeviceType(bIsIpad || bIsIphoneOs || bIsIphone || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM ? "mobile" : "desktop")
    }
  }, [])

  const bindYoukuMessageListener = useCallback((e) => {
    var data = e.data;
    switch (data.msg) {
      case "onPlayStart": {
        onPlayerStart();
        break;
      }
      case "onTimeUpdate": {
        if (data.time > 0 && !isReady && isCurrentSlide) {
          setIsReady(true)
        }
        break;
      }

      case "onPlayEnd": {
        if (canUseDOM) {
          document.getElementById(`sliderYoukuplayer_${index}`).querySelector('iframe').remove()
          setIsReady(false)
          openYoukuPlayer()
        }
        break;
      }
    }
  })

  useEffect(() => {
    if (canUseDOM) {
      if (deviceType === "desktop") {
        if (isCurrentSlide && playFirstYoukuVideo) {
          window.addEventListener('message', bindYoukuMessageListener);
          openYoukuPlayer()
          setIsReady(false);
        } else if (!isCurrentSlide) {
          setIsReady(false);
          const playerSlide = document.getElementById(`sliderYoukuplayer_${index}`).querySelector('iframe');
          if (playerSlide) {
            playerSlide.remove()
          }
        }
      } else if (deviceType === "mobile") {
        if (isCurrentSlide && playFirstYoukuVideo) {
          setIsReady(false);
          openYoukuPlayer()
        } else if (!isCurrentSlide) {
          setIsReady(false);
          const ykplayer = document.getElementById(`sliderYoukuplayer_${index}`).querySelector('.ykplayer');
          if (ykplayer) {
            ykplayer.remove()
          }
        }
      }
    }

    return () => {
      if (canUseDOM && deviceType === "desktop") {
        window.removeEventListener('message', bindYoukuMessageListener);
      } else if (deviceType === "mobile") {
        const ykplayer = document.getElementById(`sliderYoukuplayer_${index}`).querySelector('.ykplayer');
        if (ykplayer) {
          ykplayer.remove()
        }
      }

    };
  }, [deviceType, isCurrentSlide, playFirstYoukuVideo]);

  /*global YKU */

  const openYoukuPlayer = () => {

    // Get video id.
    let videoID = ""
    if (youkuVideoURL && youkuVideoURL.match(/id_(\w+)/)) {
      videoID =
        youkuVideoURL &&
        youkuVideoURL.match(/id_(\w+)/).length > 0 &&
        youkuVideoURL.match(/id_(\w+)/)[1];
    } else {
      const lastIndex = youkuVideoURL && youkuVideoURL.lastIndexOf('/');
      videoID = youkuVideoURL && youkuVideoURL.substr(lastIndex + 1);
    }
    if (videoID.length > 0) {
      try {
        player = new YKU.Player(divID, {
          styleid: '0',
          client_id: YOUKU_PLAYER_API_CLIENT_KEY,
          vid: videoID,
          width: '100%',
          height: '100%',
          autoplay: true,
          newPlayer: false,
          events: {
            onPlayerReady: () => onYoukuPlayerReady(),
            onPlayStart: () => onPlayerStart(),
            onPlayEnd: () => onPlayerEnd()
          }
        });
      } catch (error) {
        console.log("Error: Youku player")
      }
    }
  };

  function onYoukuPlayerReady() {
    console.log("onYoukuPlayerReady")
    if (canUseDOM && deviceType === "mobile" && player) {
      setTimeout(() => {
        setIsReady(true)
        player.playVideo();
      }, 1000)
    }
  }
  function onPlayerStart() {
    console.log("started.... ")
  }

  function onPlayerEnd() {
    if (canUseDOM && deviceType === "mobile" && player) {
      player.playVideo();
    }
  }

  return (
    <div className={classes.youkuWrapper}
      itemScope
      itemProp="video"
      itemType="https://schema.org/VideoObject">
      {posterImage && <img itemProp="image" className={'poster-img'} src={posterImage} style={{ opacity: isReady ? 0 : 1, }} alt="video poster img" />}
      <meta itemProp="contentUrl" content={youkuVideoURL} />
      <div id={divID} className={clsx(classes.youkuPlayer)} />
    </div>
  )
}

export default YoukuVideo;