import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .weather-widget': {
      zIndex: 5,
      position: 'absolute',
      transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',
      ...theme.mixins.left('1.5rem'),
      bottom: pxToRem(64),
      direction: 'ltr',
      '&.hidden': {
        transform: 'translateX(-100px)',
        opacity: '0',
      },
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.right('5.5%'),
        ...theme.mixins.left('auto'),
        bottom: '5rem',
      },
    },
    '& .video-stopped .plyr__poster': {
      opacity: '1',
    },
    '& .plyr__element--hide': {
      display: 'none',
    },
    '& .close-btn': {
      color: theme.mixins.Black(),
      position: 'absolute',
      top: '0',
      ...theme.mixins.right(0),
      zIndex: props => props.zIndex + 1 || '11',
      width: pxToRem(40),
      height: pxToRem(40),
      background: theme.mixins.White(900),
      borderRadius: borderRadius.circle,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: pxToRem(10),

      [theme.breakpoints.up('lg')]: {
        width: pxToRem(56),
        height: pxToRem(56),
      },

      '&:hover': {
        borderColor: 'transparent',
        background: theme.mixins.White(),
      },

      '&.plyr__element--hide': {
        display: 'none',
      },

      '&.small': {
        top: `${pxToRem(24)} !important`,
        right: `${pxToRem(24)} !important`,
        width: pxToRem(48),
        height: pxToRem(48),
        margin: 0,
        [theme.breakpoints.down('sm')]: {
          top: `${pxToRem(16)} !important`,
          right: `${pxToRem(16)} !important`,
          width: pxToRem(32),
          height: pxToRem(32),
        },
      },
    },
    '& .gradient-bg': {
      background:
        `linear-gradient(180deg, ${theme.mixins.Black(400)} 0%, ${theme.mixins.Black(195.1)} 21%, ${theme.mixins.Black(105.4)} 52%,  ${theme.mixins.Black(400)} 74%, ${theme.mixins.Black(450)} 100%)`,
      zIndex: '2',
      height: '100%',
      width: 'auto',
      position: 'absolute',
      ...theme.mixins.left(0),
      top: '0',
      transition: 'all 300ms ease-out',
    },
    '& .slick-initialized': {
      '&.fullscreen': {
        height: '100vh',
        '& .slick-track': {
          height: '100vh',
        },
        '& .slide-dots': {
          bottom: '-14rem',
        },
      },
    },

    '&  [dir="ltr"] .slick-slide': {
      float: 'left',
    },

    '& .slick-initialized .slick-slide': {
      transition: 'height 750s cubic-bezier(0.94, 0.02, 0.59, 0.97)',
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        height: 'calc(100vh - 100px)',
      },

      '& picture': {
        height: '100vh',
      },
      '& .img-to-animate-wrapper': {
        height: '100vh',
        '& picture': {
          height: '100%',
          width: '100%',
        },
      },

      '& .slide, & .plyr__control--overlaid': {
        visibility: 'hidden',
        opacity: 0,
      },

      '&.slick-active': {
        '& .slide, & .plyr__control--overlaid': {
          visibility: 'visible',
          opacity: 1,
        },
        '& .slide.hidden, & .plyr--playing .plyr__control--overlaid': {
          visibility: 'hidden',
          opacity: 0,
        },
        '& .plyr__control--overlaid.plyr__element--hide': {
          opacity: '0',
          visibility: 'hidden',
        },
      },
      '&.fullscreen': {
        height: '100vh',
      },
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    '& .slick-thumb li': {
      borderTop: `5px solid ${theme.mixins.Black(400)}`,
      animation: '500ms ease-out slideInFromBottom',
      width: 'auto',
      height: 'auto',
      flex: '1',
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        width: pxToRem(192),
        height: pxToRem(118),
      },
    },
    '& .slick-thumb': {
      display: 'flex !important',
      transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',
      '&.hidden': {
        transform: 'translateY(65px)',
      },
      '& img': {
        width: '100%',
        height: '100%',
        filter: 'grayscale(100%)',
        display: 'inherit',
      },
      '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
        transition: 'all 1s ease',
        [theme.breakpoints.up('lg')]: {
          '&:hover': {
            transform: 'scale3d(1.1, 1.1, 1.1)',
          },
        },
      },
      '& li:first-child': {
        animationDelay: '500ms',
      },
      '& li:nth-child(2)': {
        animationDelay: '750ms',
      },
      '& li:last-child': {
        animationDelay: '1s',
      },
    },
    '& .slick-thumb .slick-active img': {
      filter: 'grayscale(0%)',
    },
    '& .slick-dots .slick-active': {
      borderTopColor: '#fff',
    },
    '& .slick-dots': {
      width: 'auto',
      height: 'auto',
      bottom: theme.spacing(-7),
      ...theme.mixins.right('5.5%'),
      [theme.breakpoints.down('lg')]: {
        ...theme.mixins.right('50%'),
        transform: 'translateX(50%)',
      },
      [theme.breakpoints.down('sm')]: {
        ...theme.mixins.right(0),
        transform: 'none',
        width: '100%',
      },
      '& li': {
        margin: '0',
      },
      '&.hidden': {
        bottom: '-7.5rem',
      },
    },

    '& .plyr': {
      height: '100vh',
      [theme.breakpoints.up('lg')]: {
        height: 'calc(100vh - 100px)',

        '&.plyr--playing, &.plyr--paused': {
          height: '100vh',
        },
        '&.plyr--stopped': {
          height: 'calc(100vh - 100px)',
        },
        '&.video-stopped, &.plyr--paused.video-stopped': {
          height: 'calc(100vh - 100px)',
        },
      },
      '& iframe': {
        height: '100vh',
        opacity: 0,
        visibility: 'hidden',
      },
      '& .plyr__video-wrapper': {
        height: '100%',
      },

      '& .plyr__poster': {
        backgroundSize: 'cover',
      },
    },
    '& .plyr__control--overlaid': {
      width: theme.spacing(6),
      height: theme.spacing(6),
      ...theme.mixins.right('auto'),
      bottom: pxToRem(70),
      transform: `translate(calc(50% - 48px), calc(-50% - ${pxToRem(1)}))`,
      background: 'transparent',
      border: '2px solid #fff',
      zIndex: '10',
      transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',
      padding: '0',

      [theme.breakpoints.down(400)]: {
        transform: `translate(calc(50% - 48px), calc(-50% - ${pxToRem(25)}))`,
      },

      [theme.breakpoints.up('lg')]: {
        padding: pxToRem(34),
        ...theme.mixins.right(theme.spacing(44)),
        top: 'auto',
        bottom: theme.spacing(36),
        ...theme.mixins.left('auto'),
        width: theme.spacing(13.5),
        height: theme.spacing(13.5),
        transform: 'none',
      },

      '&.plyr__control--pressed': {
        transform: 'translateX(-100px)',
      },
      '& svg': {
        margin: 'auto',
        ...theme.mixins.positionLR(pxToRem(1), 'auto'),
        height: theme.spacing(1.5),
        width: theme.spacing(1.5),
        [theme.breakpoints.up('lg')]: {
          height: theme.spacing(5),
          width: theme.spacing(5),
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.down('lg')]: {
        ...theme.mixins.right('5rem'),
      },
    },
    '&.hidden': {
      transform: 'translateX(-100px)',
      opacity: '0',
    },
  },
  youkuPlayer: {
    position: 'absolute',
    zIndex: props => props.zIndex || 10,
    height: '0vh',
    width: '100%',
    top: 0,
    left: 0,
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
    '@media (max-width: 850px) and (orientation: landscape)': {
      height: '100% !important',
    },
  },
  playIcon: {
    position: 'absolute',
    width: pxToRem(48),
    height: pxToRem(48),
    color: theme.mixins.White(),
    borderWidth: pxToRem(2),
    borderColor: theme.mixins.White(),
    zIndex: props => props.zIndex - 1 || 8,
    transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',
    borderStyle: 'solid',
    '& svg': {
      width: pxToRem(18),
      height: pxToRem(18),
      position: 'relative',
      left: pxToRem(2),
    },
    [theme.breakpoints.up('lg')]: {
      width: pxToRem(109),
      height: pxToRem(109),
      '& svg': {
        width: pxToRem(40),
        height: pxToRem(40),
      },
    },
    '&.hidden': {
      transform: 'translateX(-100px)',
      opacity: '0',
    },
  },
}));

export default useStyles;
