import React, { useEffect } from 'react';
import YoukuPlayer from '../../../components/molecules/YoukuPlayer';
import Picture from '../../../components/atoms/Picture';
import { NoSsr, useMediaQuery } from '@material-ui/core';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import aspectRatios from '../../../utils/AspectRatios';
import get from 'lodash/get';
import { isInsideChina } from '../../../utils/cookie';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { analyticsData } from '../../../../../src/constants/Analytics/analytics-data';
import {
  getComponentName,
  getPageName,
} from '../../../utils/getPageComponentInfo';
import { plyrVideo } from '../../../utils/video';
import {
  createDynamicLabels,
} from '../../../../../src/utils/analytics';
import { canUseDOM } from '../../../utils/canUseDOM';
import Button from '../../../components/atoms/Button';
import DctSvgIcon from '../../../components/atoms/Svg';
import isEmpty from 'lodash/isEmpty';
import CustomVideo from '../../../components/atoms/Video';

const DisplayImageOrVideo = (props) => {
  const { mediaField, aspectRatio = aspectRatios['3x2'], sitecoreContext, isParalax = true, from = '', playVideo, isEventsHeroSlider, playOnBoth } = props
  const pageName = getPageName(props);
  const componentName = getComponentName(props);
  let videoUrl = get(mediaField, 'video.value', '') || '';

  const isMobile = useMediaQuery(`(max-width:${1023}px)`);


  const title = ''
  const heading = ''
  let playerDOMRef = null;

  const setPlayerDOMRef = ref => {
    playerDOMRef = ref;
  };

  useEffect(() => {
    if ((!isInsideChina(props.sitecoreContext) && canUseDOM, isMobile)) {
      plyrVideo({
        playerDOMRef: playerDOMRef,
        shouldShowPoster: false,
        handleOpenState: true,
        handleMediaGalleryPlayback: true,
      });
    }
  }, [isMobile]);

  const anayltyicsTitle = createDynamicLabels([
    pageName,
    componentName,
    `${get(sitecoreContext, 'route.name', '')}-${heading && heading.value}`,
  ]);

  useEffect(() => {
    if (!isInsideChina(sitecoreContext) && !isMobile && videoUrl && videoUrl.length !== 0) {
      let plyrDOM = document.querySelector('.plyr-video');
      const plyrDOMInterval = setInterval(() => {
        plyrDOM = document.querySelector('.plyr-video');
        if (plyrDOM) {
          clearInterval(plyrDOMInterval);
          plyrVideo({
            playerDOMRef: playerDOMRef,
            analyticsData: analyticsData.pageBodyInteraction.videoPlayClick,
            titleText: anayltyicsTitle,
            shouldShowPoster: false,
          });
        }
      }, 100);
    }
  }, []);

  // Desktop fallback image
  if (!get(mediaField, 'image.value.src', '') && videoUrl.length === 0) {
    mediaField.image = {
      value: {
        src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
        '3x2': {
          large: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
          small: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
        },
      },
    }
  }

  //mobile fallbackImage
  if (!get(mediaField, 'mobileImage.value.src', '') && videoUrl.length === 0) {
    mediaField.mobileImage = {
      ...mediaField.image,
    };
  }

  //This will check user is inside china or chinese language is selected.
  if (isInsideChina(sitecoreContext)) {
    //check if variant is video and youku player url is there.
    videoUrl = get(mediaField, 'youkuVideo.value.href', '') || '';
    if (!videoUrl.includes('youku')) {
      videoUrl = '';
    }
  }

  return (
    <>
      {isEmpty(videoUrl) ? (
        <Picture
          media={{
            image: mediaField.image,
            mobileImage: mediaField.mobileImage,
            aspectRatio,
          }}
          isParalax={isParalax}
          alternateAltText={heading && heading.value}
        />
      ) : isInsideChina(sitecoreContext) ? (
        <YoukuPlayer
          {...{
            media: {
              image: mediaField.image,
              aspectRatio,
            },
            playerHeight: '100%',
            sitecoreContext,
            youkuVideo: mediaField.youkuVideo,
            fallbackImage: mediaField.fallbackImage,
            anayltyicsTitle,
            componentName,
            pageName,
            alternateAltText: title && title.value,
          }}
        />
      ) :
        (
          <>
            <NoSsr>
              <Button
                size="medium"
                iconOnly={true}
                iconType="outlined"
                srText="Play"
                className="playIcon"
                onClick={() => { playOnBoth ? playVideo(videoUrl) : (!isMobile && playVideo(videoUrl)) }}
              >
                <DctSvgIcon name="PlayArrowIcon" />
              </Button>
            </NoSsr>
            <Picture
              className={isEventsHeroSlider ? 'overlayPicture' : ''}
              media={{
                image: mediaField.image,
                mobileImage: mediaField.mobileImage,
                disableLazyLoad: true,
                aspectRatio,
              }}
              isParalax={isParalax}
              alternateAltText={heading && heading.value}
            />
          </>
        )}
    </>
  )

}



export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(DisplayImageOrVideo))
);