import React, { useEffect } from 'react';

import Picture from '../../../../components/atoms/Picture';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { get } from 'lodash';

import { plyrVideo } from '../../../../utils/video';
import CustomVideo from '../../../atoms/Video';
import Typography from '../../../atoms/Typography';
import DctSvgIcon from '../../../atoms/Svg';
import { analyticsData } from '../../../../../constants/Analytics/analytics-data';
import aspectRatios from '../../../../utils/AspectRatios';
import YoukuPlayer from '../../../molecules/YoukuPlayer';
import { isInsideChina } from '../../../../utils/cookie';
import { createDynamicLabels } from '../../../../../utils/analytics';

const Slide = props => {
  const { titleText, componentName, pageName, handleOpenState, aspectRatio } = props;
  const {
    image,
    mobileImage,
    video,
    location,
    youkuVideo,
    fallbackImage,
  } = get(props, 'slide.fields', {});
  let playerDOMRef = '';
  const setPlayerDOMRef = ref => {
    playerDOMRef = ref;
  };
  const anayltyicsTitle = createDynamicLabels([
    pageName,
    componentName,
    `${get(props, 'sitecoreContext.route.name', '')}-${titleText}`,
  ]);
  useEffect(() => {
    if (!isInsideChina(props.sitecoreContext) && get(video, 'value.href', '')) {
      plyrVideo({
        playerDOMRef: playerDOMRef,
        analyticsData: get(analyticsData, 'pageBodyInteraction.videoPlayClick'),
        titleText: anayltyicsTitle,
        shouldShowPoster: false,
        handleOpenState,
      });
    }
  }, []);
  let hasVideo =
    get(video, 'value.href', '') && get(video, 'value.href', '').length > 0;
  //This will check user is inside china or chinese language is selected.
  if (isInsideChina(props.sitecoreContext)) {
    //check if variant is video and youku player url is there.
    hasVideo =
      get(youkuVideo, 'value.href', '') &&
      get(youkuVideo, 'value.href', '').length > 0;
  }

  return (
    <div className="slide-content-wrapper">
      {hasVideo ? (
        isInsideChina(props.sitecoreContext) ? (
          <YoukuPlayer
            {...{
              media: {
                image,
                mobileImage,
                aspectRatio: aspectRatios[aspectRatio],
              },
              sitecoreContext: props.sitecoreContext,
              youkuVideo,
              fallbackImage,
              anayltyicsTitle,
              componentName,
              pageName,
              alternateAltText: titleText,
            }}
          ></YoukuPlayer>
        ) : (
          <CustomVideo
            video={video}
            posterImage={image}
            mobileImage={mobileImage}
            aspectRatio={aspectRatios[aspectRatio]}
            setPlayerDOMRef={setPlayerDOMRef}
            alternateAltText={titleText}
          />
        )
      ) : (
        <Picture
          media={{ image, mobileImage, aspectRatio: aspectRatios[aspectRatio] }}
          alternateAltText={titleText}
          isParalax={false}
        />
      )}
      {location && location.value && (
        <Typography
          variant="body2"
          color="textSecondary"
          className="location-info"
        >
          <span className="locationIcon">
            <DctSvgIcon name="LocationOnIcon" />
          </span>
          <Text field={location} />
        </Typography>
      )}
    </div>
  );
};

export default withSitecoreContext()(withNamespaces()(Slide));
