import React from 'react';
import {
  NextArrow,
  PrevArrow,
} from '../../../components/molecules/CustomArrows';

export const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  lazyLoad: 'progressive',
  speed: 1000,
  swipe: false,
  fade: true,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        swipe: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        speed: 0,
      },
    },
  ],
};

export const settingsInner = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: true,
  swipeToSlide: true,
  lazyLoad: 'progressive',
  speed: 400,
  easing: "ease-out",
  swipe: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,

  responsive: [
    {
      breakpoint: 1299,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
