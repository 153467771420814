import * as actionTypes from './actionTypes';


export const initializeFirstVideo = payload => {
  return {
    type: actionTypes.PLAY_FIRST_YOUTUBE_VIDEO,
    payload,
  };
};

export const initializeFirstYoukuVideo = payload => {
  return {
    type: actionTypes.PLAY_FIRST_YOUKU_VIDEO,
    payload,
  };
};

export const initializeAllVideos = payload => {
  return {
    type: actionTypes.PLAY_ALL_YOUTUBE_VIDEOS,
    payload,
  };
};

export const setSeekToZeroOfSlide = payload => {
  return {
    type: actionTypes.SET_SEEK_TO_ZERO_OF_SLIDE,
    payload,
  };
};

