export const parseToLDJson = (slidesData, language) => {
  const output = slidesData.map(item => {
    const { fields } = item;
    const carouselOutput = parseCarouselLDJson(fields.carouselItems)
    const itemsObj = [
      {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "contentUrl": language !== "zh" ? fields.bgVideo.value.href : fields.youkuVideo.value.href
      },
      language !== "zh" && {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "contentUrl": fields.bgVideoMobile.value.href
      },
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": fields.image.value.src
      },
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": fields.mobileImage.value.src
      },
    ]
    return [...itemsObj, ...carouselOutput]
  });

  return JSON.stringify([].concat.apply([], output))
}

const parseCarouselLDJson = (carouselItems) => {
  return carouselItems.map(item => {
    return {
      "@context": "https://schema.org",
      "@type": "ImageObject",
      "contentUrl": item.fields.image.value.src
    }
  })
}