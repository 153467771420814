import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  youkuWrapper: {
    height: 'calc(var(--vh-static) * 100 + 47px)',
    width: 'calc(100vw)',
    position: 'relative',

    '& .poster-img': {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      display: 'block',
      zIndex: 1,
    },

    '& .hidden': {
      opacity: 0,
    },
    '& .shown': {
      opacity: 1,
    },
  },
  youkuPlayer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
    '@media (max-width: 850px) and (orientation: landscape)': {
      height: '100% !important',
    },
  },

  '@global': {
    '.ykplayer': {
      '& video': {
        objectFit: 'cover'
      },

      '& .x-video-button': {
        opacity: 0,
      },
      '& .x-video-loading': {
        display: 'none !important'
      },
      '& .x-video-title': {
        display: 'none !important'
      },
      '& .x-youkulogo': {
        display: 'none !important'
      },

    },

  }
}));

export default useStyles;
