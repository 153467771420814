import { canUseDOM } from './canUseDOM';
import { YOUKU_PLAYER_API_CLIENT_KEY } from '../../constants';

/*global YKU */
export const youkuPlayerVideo = ({
  divID = 'youkuplayer',
  videoID,
  clientID = YOUKU_PLAYER_API_CLIENT_KEY,
  playerHeight = '100vh',
  scrollToTopNeeded = false,
  uniqueVidId = '',
  posterItem = '',
  youkuPlayIconDiv = '',
}) => {
  const poster = posterItem;
  poster && poster.firstChild && poster.firstChild.remove();
  try {
    new YKU.Player('vid-' + uniqueVidId, {
      styleid: '0',
      client_id: clientID,
      vid: videoID,
      width: '100%',
      height: '100%',
      autoplay: true,
      newPlayer: true,
    });
  } catch (error) {
    console.log('An error occured while playing youku video', error);
  }

  let headerComponent,
    slickSlider,
    slide,
    slideThumbs,
    weatherWidget,
    pauseOnScroll,
    overlayWrapper,
    youkuPlayIcon;

  const para = canUseDOM && document.createElement('p');
  para.classList.add('close-btn');
  para.classList.add('small');
  const node = canUseDOM && document.createTextNode('X');
  para.appendChild(node);
  poster && poster.parentNode && poster.parentNode.insertBefore(para, poster);
  youkuPlayIcon = youkuPlayIconDiv;
  youkuPlayIcon && youkuPlayIcon.classList.add('hidden');

  headerComponent = canUseDOM && document.querySelector('header');
  headerComponent && headerComponent.classList.add('hidden');
  weatherWidget = canUseDOM && document.querySelectorAll('.weather-widget');
  weatherWidget && weatherWidget.forEach(item => item.classList.add('hidden'));

  slickSlider = canUseDOM && document.querySelectorAll('.slick-initialized')[0];
  if (slickSlider) {
    slide = slickSlider.querySelectorAll('.slick-slide');
    slideThumbs = slickSlider.querySelector('.slick-dots');
  }
  overlayWrapper = canUseDOM && document.querySelector('.overlay-wrapper');

  const actionsOnClose = () => {
    poster && poster.firstChild && poster.firstChild.remove();

    slide &&
      slide.forEach(item => {
        item.querySelector('.slide') &&
          item.querySelector('.slide').classList.remove('hidden');
        item.classList.add('fullscreen');
      });
    slickSlider && slickSlider.classList.remove('fullscreen');
    slideThumbs && slideThumbs.classList.remove('hidden');
    overlayWrapper && overlayWrapper.classList.remove('video-playing');
    overlayWrapper && overlayWrapper.classList.remove('youku-video');
    headerComponent && headerComponent.classList.remove('hidden');
    youkuPlayIcon && youkuPlayIcon.classList.remove('hidden');
    weatherWidget &&
      weatherWidget.forEach(item => item.classList.remove('hidden'));
    para && para.remove();
    if (poster && poster.style) {
      poster.style.height = '0vh'
    }
    scrollToTopNeeded && document.removeEventListener('scroll', pauseOnScroll);
  };

  pauseOnScroll = () => {
    scrollToTopNeeded && actionsOnClose();
  };

  if (scrollToTopNeeded && canUseDOM && window.pageYOffset !== 0) {
    window.scroll({
      top: '0',
      left: '0',
      behavior: 'smooth',
    });
    setTimeout(() => {
      document.addEventListener('scroll', pauseOnScroll);
      headerComponent && headerComponent.classList.add('hidden');
    }, 1000);
  } else {
    document.addEventListener('scroll', pauseOnScroll);
  }

  slide &&
    slide.forEach(item => {
      item.querySelector('.slide') &&
        item.querySelector('.slide').classList.add('hidden');
      item.classList.add('fullscreen');
    });
  slickSlider && slickSlider.classList.add('fullscreen');
  slideThumbs && slideThumbs.classList.add('hidden');
  overlayWrapper && overlayWrapper.classList.add('video-playing');
  overlayWrapper && overlayWrapper.classList.add('youku-video');
  para.addEventListener('click', () => {
    actionsOnClose();
  });
  if (poster && poster.style) {
    poster.style.height = playerHeight;
  }
};
