import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DctSvgIcon from '../Svg';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';
import clsx from 'clsx';

const Overlay = props => {
  const {
    children,
    title,
    primaryCTA,
    buttonProps,
    maxWidthOfDialog = '100%',
    className,
    isVisible,
    setIsVisible,
    addAnalyticsInfo,
    withButton = true,
    enableClickAway = false,
    from = '',
    node,
    isFlex,
    ...other
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles(props);

  const handleClickOpen = () => {
    addAnalyticsInfo && addAnalyticsInfo();
    setOpen(true);
    setIsVisible(true);
  };
  const handleClose = (e) => {
    e.preventDefault()
    setOpen(false);
    setIsVisible(false);
  };

  return (
    <div className={clsx({
      [classes.displayFlex]: from === 'StickyTitle',
      [classes.pr10]: from === 'StickyTitle',
    })}>
      {from === 'StickyTitle' && node &&
        <>
          {node}
        </>
      }
      {withButton && (
        <Button
          component="span"
          buttonType="primary"
          field={primaryCTA}
          onClick={handleClickOpen}
          {...buttonProps}
          className={clsx([buttonProps.className], {
            [classes.vdiv]: from === 'StickyTitle',
          })}
        />
      )}
      <Dialog
        onClose={handleClose}
        open={open || isVisible}
        fullWidth={true}
        className={`${classes.root} ${className}`}
        fullScreen={isFlex ? true : false}
        {...other}
      >
        <Button
          iconOnly={true}
          className={`${classes.closeButton} overlayCloseBtn`}
          onClick={handleClose}
          srText={'Close'}
        >
          <DctSvgIcon name="CloseIcon" />
        </Button>

        <MuiDialogTitle disableTypography>
          <Typography
            variant="h4"
            component="h2"
            className={`${className && className.title}`}
          >
            {title}
          </Typography>
        </MuiDialogTitle>
        {enableClickAway ? (
          <ClickAwayListener onClickAway={handleClose}>
            <MuiDialogContent
              dividers={false}
              className={className && className.content}
            >
              {children}
            </MuiDialogContent>
          </ClickAwayListener>
        ) : (
          <MuiDialogContent
            dividers={false}
            className={className && className.content}
          >
            {children}
          </MuiDialogContent>
        )}
      </Dialog>
    </div>
  );
};

Overlay.propTypes = {
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
};

Overlay.defaultProps = {
  isVisible: false,
  setIsVisible: () => { },
};

export default Overlay;
