import React, { useState, useEffect } from 'react';
import DctSvgIcon from '../../atoms/Svg';
import { youkuPlayerVideo } from '../../../utils/YoukuVideo';
import Picture from '../../atoms/Picture';
import Button from '../../atoms/Button';
import { LANGUAGE_CODE } from '../../../../constants';
import useStyles from './style';
import { get } from 'lodash';
import {
  pushAnaylyticsData,
  createDynamicLabels,
} from '../../../../utils/analytics';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import { isLanguageEqual } from '../../../utils/cookie';
import { canUseDOM } from '../../../utils/canUseDOM';

const YoukuPlayer = props => {
  // this component has playIcon props also which is
  // responsible to handle custom play button

  const {
    fallbackImage,
    media,
    playIconClass,
    zIndex,
    anayltyicsTitle,
    componentName,
    pageName,
    alternateAltText,
    customClassPicture = '',
    uniqueId = '',
  } = props;
  const language = get(
    props,
    'sitecoreContext.language',
    LANGUAGE_CODE.DEFAULT
  );
  const [uniqueVidId, setUniqueVidId] = useState('')

  useEffect(() => {
    setUniqueVidId(uniqueId)
  }, [uniqueId])


  const classes = useStyles({ zIndex });
  const youkuVideoURL = get(props, 'youkuVideo.value.href');
  let videoID;

  if (youkuVideoURL && youkuVideoURL.match(/id_(\w+)/)) {
    videoID =
      youkuVideoURL &&
      youkuVideoURL.match(/id_(\w+)/).length > 0 &&
      youkuVideoURL.match(/id_(\w+)/)[1];
  } else {
    // if such URLs (https://player.youku.com/embed/XMjg1Mjc0MzU0OA==) are configured

    const lastIndex = youkuVideoURL && youkuVideoURL.lastIndexOf('/');
    videoID = youkuVideoURL && youkuVideoURL.substr(lastIndex + 1);
  }


  const addAnalyticsInfo = () => {
    const obj = {
      ...analyticsData.pageBodyInteraction.videoPlayClick,
      ...{
        label: createDynamicLabels([pageName, componentName, anayltyicsTitle]),
      },
    };
    pushAnaylyticsData(obj);
  };


  const openYoukuPlayer = (e) => {
    const parentDiv = e.target.closest('.youku-player')
    const specificDiv = parentDiv.querySelector('.yoko-plyr-main');
    const youkuPlayIconDiv = parentDiv.querySelector('.youkuPlayIcon')
    addAnalyticsInfo();
    if (videoID.length > 0 && canUseDOM) {
      youkuPlayerVideo({ ...{ ...props, videoID, uniqueVidId, posterItem: specificDiv, youkuPlayIconDiv } });
    }
  };
  if (!isLanguageEqual(language, LANGUAGE_CODE.CHINESE) && media) {
    media.image = fallbackImage;
  }
  return (
    <div className={`${!playIconClass && classes.root} youku-player`}>
      <Picture media={{ ...media }} alternateAltText={alternateAltText} className={`youku-picture ${customClassPicture}`} />
      <div className="gradient-bg"></div>
      <div id={'vid-' + uniqueVidId} className={`${classes.youkuPlayer} yoko-plyr-main`} />
      {isLanguageEqual(language, LANGUAGE_CODE.CHINESE) && videoID.length > 0 && (
        <Button
          size="medium"
          iconOnly={true}
          iconType="outlined"
          srText="Play"
          onClick={openYoukuPlayer}
          className={`${!playIconClass ? classes.playIcon : playIconClass
            } youkuPlayIcon  ${'youkuPlayIcon' + uniqueVidId}`}
        >
          <DctSvgIcon name="PlayArrowIcon" />
        </Button>
      )}
    </div>
  );
};

export default YoukuPlayer;
