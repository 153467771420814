import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  backgroundVideo: {
    position: 'relative',
    '&:not(.is-html-video)': {
      height: 'calc(56.25vw + 136px)',
      width: 'calc(100vw + 241px)',
      marginTop: '-68px',
      left: '-120px',
      '&.ratio-3x5': {
        '@media (max-width: 767px)': {
          height: 'calc(166.66vw  + 136px)',
          width: 'calc(100vw + 241px)',
          '@media(max-aspect-ratio: 3 / 5)': {
            /* width = 100 / (5 / 3) = 59.99 */
            width: 'calc((var(--vh-static, 1vh) * 59.99) + 241px)',
            height: 'calc((var(--vh-static, 1vh) * 100) + 136px)',
            marginTop: '-68px',
          },
        },
        '@media (max-width: 500px)': {
          '.max-height-500 &': {
            /* width = 100 / (5 / 3) = 59.99 */
            width: 'calc(500px + 241px)',
            height: 'calc(500px * 1.666 + 136px)',
            marginTop: '-68px',
          },
        },
      },
      '&.ratio-16x9': {
        '@media(max-aspect-ratio: 16 / 9)': {
          /* width = 100 / (9 / 16) = 177.777777 */
          width: 'calc((var(--vh-static, 1vh) * 177.78) + 241px)',
          height: 'calc((var(--vh-static, 1vh) * 100) + 136px)',
          marginTop: '-68px',
        },
        '@media (max-width: 500px)': {
          '.max-height-500 &': {
            /* width = 100 / (9 / 16) = 177.777777 */
            width: 'calc(500px * 1.77 + 241px)',
            height: 'calc(500px + 136px)',
            marginTop: '-68px',
          },
        },
      },
    },
    '&.is-html-video': {
      height: '100%',
      width: '100%',
    },
    '& video': {
      backgroundColor: 'transparent',
      objectFit: 'cover',
      width: '100vw',
      height: 'calc(var(--vh-static, 1vh) * 100)',
    },

    '&.show-video': {
      '& .poster-img': {
        opacity: 0,
      },
    },

    '& .poster-img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      display: 'block',
      transition: 'opacity 0.25s',
      zIndex: 1,
    },
  },
}));

export default useStyles;
