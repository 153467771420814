import React, { useState, useEffect } from 'react';
import Picture from '../../../../components/atoms/Picture';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { get } from 'lodash';
import aspectRatios from '../../../../utils/AspectRatios';
import { isInsideChina } from '../../../../components/../utils/cookie';
import YoukuVideo from '../../../../components/atoms/YoukuVideo';
import BackgroundVideo from '../../../../components/atoms/BackgroundVideo';

const Slide = props => {
  const {
    image,
    mobileImage,
    title,
    youkuVideo,
    bgVideo,
    bgVideoMobile,
  } = get(props, 'slide.fields');
  const { isMobile, isCurrentSlide, index, oldIndex, newIndex, initInnerSlides } = props;

  const [showSlide, setShowSlide] = useState(false)
  // get value.href from bgVideo or bgVideoMobile if it exists & save it to videoSrc based on isMobile
  const bgVideoVal = get(bgVideo, 'value.href');
  const bgVideoMobileVal = get(bgVideoMobile, 'value.href');
  const youkuVideoVal = get(youkuVideo, 'value.href');
  const isMp4OrWebm = youkuVideoVal.indexOf('.mp4') > 0 || youkuVideoVal.indexOf('.webm') > 0;
  const isYoukuVideo = !isMp4OrWebm && youkuVideo && youkuVideo.value.href && isInsideChina(props.sitecoreContext);
  const hasVideo = bgVideoVal || bgVideoMobileVal || youkuVideoVal;
  const videoRatio = bgVideoMobileVal && (isMobile === 'mobile') ? '3x5' : '16x9';

  let videoSrc = null;
  if (hasVideo) {
    videoSrc = (isYoukuVideo || isMp4OrWebm) ? youkuVideoVal : isMobile === 'mobile' ? bgVideoMobileVal || bgVideoVal : bgVideoVal;
  }

  // get value.src from image & mobileImage
  const imageVal = get(image, 'value.src');
  const mobileImageVal = get(mobileImage, 'value.src');
  const posterImage = isMobile ? mobileImageVal || imageVal : imageVal;

  useEffect(() => {
    if (isMobile === 'desktop' && index === 0) {
      setShowSlide(true)
    } else if (isMobile === '' || isMobile === 'mobile') {
      setShowSlide(true)
    }
  }, [])

  useEffect(() => {
    if (index > 0 && initInnerSlides && (isMobile === 'desktop')) {
      setShowSlide(true)
    }
  }, [initInnerSlides])

  return (
    <div className={'mainBgSlide'}>
      {showSlide && <>
        {hasVideo && isYoukuVideo && (
          <YoukuVideo
            youkuVideo={youkuVideo.value.href}
            posterImage={image.value.src}
            index={index}
            isCurrentSlide={isCurrentSlide}
          />
        )}

        {hasVideo && !isYoukuVideo && (
          <BackgroundVideo
            videoUrl={videoSrc}
            ratio={videoRatio}
            posterImage={posterImage}
            isHTMLVideo={isMp4OrWebm}
            isCurrentSlide={isCurrentSlide}
            index={index}
            oldIndex={oldIndex}
            newIndex={newIndex}
          />
        )}

        {!hasVideo &&
          <Picture
            media={{
              image,
              mobileImage,
              aspectRatio: aspectRatios['16x9'],
              disableLazyLoad: true,
            }}
            isParalax={false}
            alternateAltText={title && title.value}
          />
        }
      </>}
    </div>
  );
};

export default withSitecoreContext()(withNamespaces()(Slide));
