import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  displayFlex: {
    display: 'flex'
  },
  pr10: {
    ...theme.mixins.paddingRight(pxToRem(13.5)),
  },
  vdiv: {
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.borderLeft(`1px solid ${theme.palette.common.DisabledGrey}`),
      ...theme.mixins.paddingLeft(pxToRem(13.5)),
    },
  },
  root: {
    background: theme.palette.common.black,
    '&.sibebarVideo': {
      '& .plyr': {
        height: '100vh',
      },
      '& .pictureWithArticle': {
        padding: '50vh',
        [theme.breakpoints.down('md')]: {
          padding: 'initial',
        },
      },
      '& .overlayCloseBtn': {
        display: 'none',
      },
      '& .MuiDialogTitle-root': {
        display: 'none',
      },
      '& .MuiDialogContent-root': {
        padding: 0,
      },
      '& .MuiBackdrop-root': {
        ...theme.mixins.positionLR(0, 0),
      },
      '& .MuiDialog-paper': {
        maxWidth: '100%',
        margin: '0',
      },
    },
    '& .MuiDialog-paper': {
      maxHeight: '100%',
    },
    '& .MuiDialogTitle-root': {
      position: 'relative',
      paddingTop: 0,
      paddingBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column-reverse',

      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        paddingTop: theme.spacing(9),
      },
    },
    '& .MuiPaper-root': {
      color: theme.palette.common.white,
      background: theme.palette.background.transparent,
    },
    '& .MuiDialog-scrollPaper': {
      display: 'inherit',
    },
    '& .MuiBackdrop-root': {
      ...theme.mixins.positionLR(0, pxToRem(16)),
    },
  },
  closeButton: {
    position: 'absolute',
    color: theme.mixins.White(),
    background: theme.mixins.Grey(400),
    zIndex: '10',
    top: theme.spacing(3),
    ...theme.mixins.right(theme.spacing(3)),
    width: pxToRem(40),
    height: pxToRem(40),
    borderRadius: borderRadius.circle,
    [theme.breakpoints.up('lg')]: {
      top: theme.spacing(4),
      ...theme.mixins.right(theme.spacing(4)),
      width: pxToRem(56),
      height: pxToRem(56),
    },
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: theme.mixins.White(300),
    },

    '& svg': {
      width: theme.spacing(2),
      height: theme.spacing(2),

      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
    },
  },
}));

export default useStyles;
