import Plyr from 'plyr/dist/plyr.polyfilled.js';
import './styles/plyr.css';

import { canUseDOM } from './canUseDOM';
import { pushAnaylyticsData } from '../../utils/analytics';

const playerInstances = [];

export const plyrVideo = ({
  playerDOMRef,
  posterImage,
  analyticsData,
  titleText,
  shouldAnimateElm = false,
  withGradient = false,
  shouldShowPoster = true,
  handleOpenState = false,
  handleMediaGalleryPlayback = false,
  fullScreenControl = false,
  autoplayVideo = false,
  from = 'other',
  iosNative = true,
}) => {
  const controls = [
    'play-large',
    'play',
    'progress',
    'current-time',
    'mute',
    'volume',
    'restart',
  ];
  const fullscreen = { enabled: fullScreenControl, iosNative };

  if (fullScreenControl) {
    controls.push('fullscreen');
  }

  const youtube = {
    noCookie: false,
    rel: 0,
    showinfo: 0,
    iv_load_policy: 3,
    modestbranding: 1,
    autoplay: autoplayVideo,
  };

  let options = {
    hideControls: false,
    clickToPlay: false,
    toggleInvert: false,
    controls,
    youtube,
    resetOnEnd: true,
    playsinline: true,
    fullscreen,
  };

  if (from === 'DualHeroSlider') {
    options = {
      ...options,
      autoplay: autoplayVideo,
      autopause: false,
      clickToPlay: !autoplayVideo,
      settings: ['loop'],
      loop: { active: true },
      muted: true,
    };
  }

  const isIos =
    (navigator.userAgent.match(/iPhone|iPad|iPod/i) && true) || false;
  isIos && document.body.classList.add('is-ios-device');

  let headerComponent,
    headerBanner,
    slickSlider,
    slide,
    slideThumbs,
    weatherWidget,
    pauseOnScroll,
    overlayWrapper,
    closeBtn,
    handlePauseOnScroll,
    flexiVisualElm;
  const removeVideoPlayedClass = $elements => {
    let controlBtn = $elements.container.parentElement.querySelector(
      '.plyr__control--overlaid--ios'
    );
    controlBtn && controlBtn.classList.remove('video-played');
    $elements.container.parentElement.classList.remove('video-played');
  };
  const addVideoPlayedClass = $elements => {
    let controlBtn = $elements.container.parentElement.querySelector(
      '.plyr__control--overlaid--ios'
    );
    controlBtn && controlBtn.classList.add('video-played');
    $elements.container.parentElement.classList.add('video-played');
  };
  try {
    const plyrObj = new Plyr(playerDOMRef, options);
    plyrObj.on('ready ended', () => {
      playerInstances.push(playerDOMRef);
      const $elements = plyrObj.elements;
      if (isIos) {
        removeVideoPlayedClass($elements);
      }
      flexiVisualElm = $elements.container.closest('.flexiVisual');
      $elements.controls.classList.add('plyr__element--hide');

      if (posterImage) {
        // add custom poster image when its available
        // otherwise youtube will add default poster image from video
        plyrObj.poster = shouldShowPoster ? posterImage : '';
        if (withGradient) {
          const container = plyrObj.elements.container;
          const gradientDiv = document.createElement('div');
          gradientDiv.setAttribute('class', 'gradient-bg');
          container.appendChild(gradientDiv);
        }
      }

      if (shouldAnimateElm) {
        headerComponent = canUseDOM && document.querySelector('header');
        headerBanner = canUseDOM && document.querySelector('.header-banner');
        let slickSliderAll =
          canUseDOM && document.querySelectorAll('[data-locator="HeroCarousel"] .slick-initialized');
        slickSliderAll.forEach(slickSlider => {
          if (slickSlider) {
            slide = slickSlider.querySelectorAll('.slick-slide');
            slideThumbs = slickSlider.querySelector('.slick-dots');
            slide &&
              slide.length > 0 &&
              slide.forEach(item => {
                const slideWrapper = item.querySelector('.slide-wrapper');
                const slideElm = item.querySelector('.slide');
                const playIcon = item.querySelector('.plyr__control--overlaid') || item.querySelector('.youkuPlayIcon');
                // check if slideWrapper has class play-cta-center
                if (playIcon) {
                  if (
                    slideWrapper &&
                    slideWrapper.classList.contains('play-cta-center')
                  ) {
                    // moving play icon to slide
                    slideWrapper.appendChild(playIcon);
                  } else if (slideElm) {
                    slideElm.appendChild(playIcon);
                  }
                }
              });

            weatherWidget =
              canUseDOM && document.querySelectorAll('.weather-widget');
          }
        });
      }

      closeBtn =
        canUseDOM &&
        $elements.container.parentElement.querySelector('.close-btn');
      overlayWrapper = canUseDOM && document.querySelector('.overlay-wrapper');

      // place play button outside of player if isIos
      if (isIos) {
        const plyrParentNode = canUseDOM && $elements.container.parentElement;
        // if is inside herocarousel then return
        if (plyrParentNode.closest('[data-locator="HeroCarousel"]')) {
          return;
        }
        if (plyrParentNode) {
          const playBtn = plyrParentNode.querySelector(
            '.plyr__control--overlaid'
          );
          if (playBtn) {
            playBtn.classList.add('plyr__control--overlaid--ios');
            // should not impact hero carousel
            document
              .querySelectorAll(
                '[data-locator="HeroCarousel"] .plyr__control--overlaid--ios'
              )
              .forEach(el => {
                el.classList.add('no-positioning');
              });
            plyrParentNode.appendChild(playBtn);
          }
        }
      }
    });

    plyrObj.once('play', () => {
      let obj = {
        ...analyticsData,
        ...{ label: titleText },
      };
      const $elements = plyrObj.elements;
      if (isIos) {
        addVideoPlayedClass($elements);
      }
      pushAnaylyticsData(obj);
      closeBtn &&
        closeBtn.addEventListener('click', () => {
          $elements.controls.classList.add('plyr__element--hide');
          $elements.buttons.play[0].classList.remove('plyr__element--hide');
          pauseOnScroll();
          if (from !== 'DualHeroSlider') {
            canUseDOM &&
              document.removeEventListener('scroll', handlePauseOnScroll);
          }
          canUseDOM && document.body.classList.remove('video-open');
          if (handleOpenState) {
            const overlayCloseBtn =
              canUseDOM && document.querySelector('.overlayCloseBtn');
            overlayCloseBtn && overlayCloseBtn.click();
            overlayWrapper =
              canUseDOM && document.querySelector('.overlay-wrapper');
            if (overlayWrapper) {
              overlayWrapper.style.display = 'none';
            }
          }
        });
      weatherWidget =
        canUseDOM &&
        weatherWidget &&
        document.querySelectorAll('.weather-widget');
    });

    const setPlyrCss = () => {
      if (isIos) {
        let plyrElem = document.querySelector('.plyr');
        plyrElem.style.top = '0';
        plyrElem.style.opacity = '0';
        plyrElem.style.zIndex = '-1';
        plyrElem.style.width = '100vw';
        plyrElem.style.position = 'absolute';
      }
    };

    plyrObj.on('play', e => {
      document.body.classList.add('video-open');
      setPlyrCss();
      pauseOnScroll = () => {
        plyrObj.pause();
        document.body.classList.remove('video-open');
        if (
          from !== 'DualHeroSlider' &&
          e.target.classList.contains('video-stopped')
        ) {
          canUseDOM && document.removeEventListener('scroll', pauseOnScroll);
        }
        e.target.classList.add('video-stopped');
        headerComponent && headerComponent.classList.remove('hidden');
        headerBanner && headerBanner.classList.remove('hidden');
        weatherWidget &&
          weatherWidget.forEach(item => item.classList.remove('hidden'));
        slickSlider && slickSlider.classList.remove('fullscreen');
        slideThumbs && slideThumbs.classList.remove('hidden');
        overlayWrapper && overlayWrapper.classList.remove('video-playing');
        handleMediaGalleryPlayback &&
          document
            .querySelector('.mediaWrapper')
            .classList.remove('video-playing');
        flexiVisualElm && flexiVisualElm.classList.remove('video-playing');
        slide &&
          slide.length > 0 &&
          slide.forEach(item => {
            item.querySelector('.slide') &&
              item.querySelector('.slide').classList.remove('hidden');
            item.querySelector('.play-cta-center .plyr__control--overlaid') &&
              item
                .querySelector('.play-cta-center .plyr__control--overlaid')
                .classList.remove('hidden');
            item.querySelector('.play-cta-center .video-cta-btn') &&
              item
                .querySelector('.play-cta-center .video-cta-btn')
                .classList.remove('hidden');
            item.querySelector('.pictureWithPoster') &&
              item.querySelectorAll('.pictureWithPoster').forEach(el => {
                el.classList.remove('plyr__element--hide');
              });
            item.querySelector('.gradient-bg') &&
              item
                .querySelector('.gradient-bg')
                .classList.remove('plyr__element--hide');
            item.classList.remove('fullscreen');
          });
        const $elements = plyrObj.elements;
        $elements.container.querySelector('iframe').style.opacity = 0;
        $elements.container.querySelector('iframe').style.visibility = 'hidden';
        if (!isIos) {
          $elements.container.querySelector(
            '.plyr__controls'
          ).style.opacity = 0;
          $elements.container.querySelector(
            '.plyr__controls'
          ).style.visibility = 'hidden';
        }
        $elements.poster.classList.remove('plyr__element--hide');
        $elements.container.querySelector('.gradient-bg') &&
          $elements.container
            .querySelector('.gradient-bg')
            .classList.remove('plyr__element--hide');
        $elements.buttons.play[0].classList.remove('plyr__element--hide');
        closeBtn && closeBtn.classList.add('plyr__element--hide');
        $elements.controls.classList.add('plyr__element--hide');
        if (from !== 'DualHeroSlider') {
          window.removeEventListener('scroll', handlePauseOnScroll);
        }
      };
      handlePauseOnScroll = function () {
        const isPlaying = $elements.container.classList.contains(
          'plyr--playing'
        );
        if (isPlaying && window.pageYOffset > (window.androidHeight || window.innerHeight)) {
          pauseOnScroll();
        } else if (
          from === 'DualHeroSlider' &&
          window.pageYOffset < (window.androidHeight || window.innerHeight)
        ) {
          plyrObj.play();
        }
      };
      if (canUseDOM && shouldAnimateElm) {
        setTimeout(() => {
          window.addEventListener('scroll', handlePauseOnScroll);
          if (window.pageYOffset !== 0) {
            window.scroll({
              top: '0',
              left: '0',
              behavior: 'smooth',
            });

            !isIos &&
              headerComponent &&
              headerComponent.classList.add('hidden');
            headerBanner &&
              headerBanner.classList.add('hidden');
          }
        }, 1000);
      }
      e.target.classList.remove('video-stopped');
      !isIos &&
        weatherWidget &&
        weatherWidget.forEach(item => item.classList.add('hidden'));
      const $elements = plyrObj.elements;
      if (!isIos) {
        headerComponent && headerComponent.classList.add('hidden');
        headerBanner && headerBanner.classList.add('hidden');
        slideThumbs && slideThumbs.classList.add('hidden');
        overlayWrapper && overlayWrapper.classList.add('video-playing');
        handleMediaGalleryPlayback &&
          document
            .querySelector('.mediaWrapper')
            .classList.add('video-playing');
        flexiVisualElm && flexiVisualElm.classList.add('video-playing');
      }

      // without fullscreen class fullscreen video ends on deivce rotate
      slickSlider && slickSlider.classList.add('fullscreen');

      $elements.container.querySelector('iframe').style.opacity = 1;
      $elements.container.querySelector('iframe').style.visibility = 'visible';
      if (!isIos) {
        $elements.container.querySelector('.plyr__controls').style.opacity = 1;
        $elements.container.querySelector('.plyr__controls').style.visibility =
          'visible';
        $elements.poster.classList.add('plyr__element--hide');
        $elements.container.querySelector('.gradient-bg') &&
          $elements.container
            .querySelector('.gradient-bg')
            .classList.add('plyr__element--hide');
      }
      if (isIos) {
        addVideoPlayedClass($elements);
      }

      !isIos &&
        slide &&
        slide.forEach(item => {
          item.querySelector('.slide') &&
            item.querySelector('.slide').classList.add('hidden');
          item.querySelector('.play-cta-center .plyr__control--overlaid') &&
            item
              .querySelector('.play-cta-center .plyr__control--overlaid')
              .classList.add('hidden');
          item.querySelector('.play-cta-center .video-cta-btn') &&
            item
              .querySelector('.play-cta-center .video-cta-btn')
              .classList.add('hidden');

          item.querySelector('.pictureWithPoster') &&
            item.querySelectorAll('.pictureWithPoster').forEach(el => {
              el.classList.add('plyr__element--hide');
            });
          item.querySelector('.gradient-bg') &&
            item
              .querySelector('.gradient-bg')
              .classList.add('plyr__element--hide');
          item.classList.add('fullscreen');
        });
      $elements.controls.classList.remove('plyr__element--hide');
      !isIos && closeBtn && closeBtn.classList.remove('plyr__element--hide');
      if (from !== 'DualHeroSlider') {
        playerInstances.forEach(instance => {
          if (instance.plyr.id !== e.detail.plyr.id) {
            instance.plyr.pause();
          }
        });
      }
    });

    plyrObj.on('pause', () => {
      const $elements = plyrObj.elements;
      if (isIos) {
        removeVideoPlayedClass($elements);
      }
      !isIos && $elements.buttons.play[0].classList.add('plyr__element--hide');
      if (from !== 'DualHeroSlider') {
        window.removeEventListener('scroll', handlePauseOnScroll);
      }
    });

    plyrObj.on('seeking', e => {
      plyrObj.play();
      e.target.classList.remove('video-stopped');
      if (!isIos) {
        headerComponent && headerComponent.classList.add('hidden');
        headerBanner && headerBanner.classList.add('hidden');
        slickSlider && slickSlider.classList.add('fullscreen');
        slideThumbs && slideThumbs.classList.add('hidden');
        weatherWidget &&
          weatherWidget.forEach(item => item.classList.add('hidden'));
      }
    });

    plyrObj.on('ended', e => {
      const $elements = plyrObj.elements;
      if (isIos) {
        removeVideoPlayedClass($elements);
      }
      $elements.buttons.play[0].classList.remove('plyr__element--hide');
      // canUseDOM && document.removeEventListener('scroll', pauseOnScroll);
      headerComponent && headerComponent.classList.remove('hidden');
      slickSlider && slickSlider.classList.remove('fullscreen');
      slideThumbs && slideThumbs.classList.remove('hidden');
      weatherWidget &&
        weatherWidget.forEach(item => item.classList.remove('hidden'));
      overlayWrapper && overlayWrapper.classList.remove('video-playing');
      handleMediaGalleryPlayback &&
        document
          .querySelector('.mediaWrapper')
          .classList.remove('video-playing');
      flexiVisualElm && flexiVisualElm.classList.remove('video-playing');
      closeBtn && closeBtn.classList.add('plyr__element--hide');
      slide &&
        slide.length > 0 &&
        slide.forEach(item => {
          item.querySelector('.slide') &&
            item.querySelector('.slide').classList.remove('hidden');
          item.querySelector('.play-cta-center .plyr__control--overlaid') &&
            item
              .querySelector('.play-cta-center .plyr__control--overlaid')
              .classList.remove('hidden');
          item.querySelector('.play-cta-center .video-cta-btn') &&
            item
              .querySelector('.play-cta-center .video-cta-btn')
              .classList.remove('hidden');
          item.querySelector('.pictureWithPoster') &&
            item.querySelectorAll('.pictureWithPoster').forEach(el => {
              el.classList.remove('plyr__element--hide');
            });
          item.querySelector('.gradient-bg') &&
            item
              .querySelector('.gradient-bg')
              .classList.remove('plyr__element--hide');
          item.classList.remove('fullscreen');
        });
      e.target.classList.add('video-stopped');
      if (from !== 'DualHeroSlider') {
        window.removeEventListener('scroll', handlePauseOnScroll);
      }
      $elements.poster.classList.remove('plyr__element--hide');
      $elements.container.querySelector('.gradient-bg') &&
        $elements.container
          .querySelector('.gradient-bg')
          .classList.remove('plyr__element--hide');
      if (handleOpenState) {
        document.querySelector('.overlayCloseBtn').click();
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const getYoutubeIDFromURL = url => {
  // This regex is copied from http://web.archive.org/web/20160926134334/http://lasnv.net/foro/839/Javascript_parsear_URL_de_YouTube
  // and it is already tested with almost all the possible youtube urls
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

export const getThumbnailFromYoutube = videoUrl => {
  const youtubeID = getYoutubeIDFromURL(videoUrl);
  // Youtube always provide thumbnail image. if url or youtube id is invalid
  // or video is removed in that case it returns a fallback image
  // NOTE: youtube provide 4 variant of thumbnail image
  // 0.jpg is the default large preview image
  return `https://img.youtube.com/vi/${youtubeID}/maxresdefault.jpg`;
};