import React, { useState, useEffect, useRef } from 'react';
import useStyles from './style';
import ReactPlayer from 'react-player';
import { useSelector, connect } from 'react-redux';
import { initializeAllVideos } from '../../../containers/common/DualHeroSlider/redux/actions';
import clsx from 'clsx';

// let aboveTheFold = true;
const BackgroundVideo = ({
  videoUrl,
  ratio = '16x9',
  posterImage,
  index,
  initializeAllVideos,
  isCurrentSlide,
  oldIndex,
  newIndex,
}) => {
  const [shoudStartVideo, setShoudStartVideo] = useState(false);
  const [shoudStartAllVideo, setShoudStartAllVideo] = useState(false);
  const player = useRef(null);
  const isHTMLVideo = videoUrl.indexOf('.mp4') > 0 || videoUrl.indexOf('.webm') > 0;

  const classes = useStyles();
  let [isReady, setIsReady] = useState(false);
  let [playedOnce, setPlayedOnce] = useState(false);
  let [isPlaying, setIsPlaying] = useState(isCurrentSlide || oldIndex === index || newIndex === index);

  const { firstYoutubeVideoIndex, playAllYoutubeVideos } = useSelector(state => state.DualHeroSliderReducer);

  useEffect(() => {
    setIsPlaying(isCurrentSlide || oldIndex === index || newIndex === index);
  }, [isCurrentSlide, index, oldIndex, newIndex]);

  useEffect(() => {
    if (firstYoutubeVideoIndex !== null && firstYoutubeVideoIndex === index) {
      setShoudStartVideo(true)
    }
  }, [firstYoutubeVideoIndex]);

  useEffect(() => {
    if (playAllYoutubeVideos) {
      setShoudStartAllVideo(true);
    }
  }, [playAllYoutubeVideos]);

  // need to work on pausing video when users scroll down, last status: Youtube wont play back
  // useEffect(() => {
  //   if (canUseDOM) {
  //     handlePauseOnScroll();
  //     window.addEventListener('scroll', handlePauseOnScroll);
  //   }
  // }, []);

  // const handlePauseOnScroll = async () => {
  //   if (canUseDOM) {
  //     let dhsHeight = window.innerHeight;
  //     // min-height of DHS is 590px
  //     if (dhsHeight < 590) {
  //       dhsHeight = 590;
  //     }
  //     if (window.pageYOffset > dhsHeight) {
  //       aboveTheFold = false;
  //     } else {
  //       aboveTheFold = true;
  //     }
  //     console.log('dhsHeight', dhsHeight);
  //     console.log('window.pageYOffset', window.pageYOffset);
  //     console.log('isCurrentSlide && aboveTheFold : ' + isCurrentSlide && aboveTheFold);
  //   }
  // };


  const onProgress = (e) => {
    if (!isReady && e.played > 0.01) {
      player.current.seekTo(0);
      setIsReady(true);
    }
  };

  const onReady = () => {
    if (!playAllYoutubeVideos) {
      setTimeout(() => {
        initializeAllVideos(true);
      }, 1000);
    }
  };

  const config = {
    youtube: {
      playerVars: {
        disablekb: 1,
        showinfo: 0,
        autohide: 1,
        modestbranding: 1,
      },
    },
  };

  return (
    <div className={clsx(
      classes.backgroundVideo,
      `ratio-${ratio}`,
      isReady && 'show-video',
      isHTMLVideo && 'is-html-video')}
      elementid={'background-video'}
    >
      {posterImage && <img className={'poster-img'} src={posterImage} alt="video poster img" />}
      {(shoudStartVideo || shoudStartAllVideo) && (
        <>
          <ReactPlayer
            ref={player}
            url={videoUrl}
            // url={isHTMLVideo ? `https://vaddev.projectuatserver.com/${videoUrl}` : videoUrl}
            onProgress={onProgress}
            progressInterval={200}
            playing={isPlaying}
            loop
            muted
            controls={false}
            onReady={onReady}
            width="100%"
            height="100%"
            playsinline={true}
            config={config}
          />
        </>
      )}
    </div>
  )
}

export const mapDispatchToProps = dispatch => {
  return {
    initializeAllVideos: params => dispatch(initializeAllVideos(params)),
  };
};

export const mapStateToProps = () => {
  return {}
}

export default (connect(mapStateToProps, mapDispatchToProps)(BackgroundVideo));